import { patch } from "@rails/request.js/src/verbs";
import SortableController from "stimulus-sortable";

export default class extends SortableController {
  private declare readonly resourceNameValue: string | null;
  private declare readonly paramNameValue: string;

  async onUpdate({ item, newIndex }) {
    if (!item.dataset.sortableUpdateUrl) return;

    const resourceKey = this.resourceNameValue ? `${this.resourceNameValue}[${this.paramNameValue}]` : this.paramNameValue;
    const data = new FormData();

    data.append(resourceKey, newIndex + 1);

    await patch(item.dataset.sortableUpdateUrl, { body: data, responseKind: "json", redirect: "manual" });

    window.location.reload();
  }
}
