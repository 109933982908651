import { Target, TypedController, Value } from "@vytant/stimulus-decorators";
import intlTelInput from "intl-tel-input";
import { Controller } from "stimulus";
import { PassedTranslations } from "~/lib/passedTranslations";

import "intl-tel-input/build/css/intlTelInput.css";
import "intl-tel-input/build/js/utils.js";
import "./component.scss";

@TypedController
export default class extends Controller {
  @Target readonly phoneFieldTarget!: HTMLInputElement;

  @Value(Boolean) readonly countrySelectEnabledValue: boolean;
  @Value(String) readonly defaultCountryCodeValue: string;
  @Value(String) translationsValue!: string;

  private intlInstance: any;
  private translations: PassedTranslations;

  private readonly errorMappings = ["invalid_number", "invalid_country_code", "too_short", "too_long", "invalid_number"];

  connect(): void {
    if (!this.countrySelectEnabledValue) {
      return;
    }

    this.translations = new PassedTranslations(this.translationsValue);

    this.intlInstance = intlTelInput(this.phoneFieldTarget, {
      nationalMode: false,
      formatOnDisplay: true,
      autoPlaceholder: "aggressive",
      showSelectedDialCode: true,
      i18n: {
        ...Object.fromEntries(this.translations.t("countriesTranslations").map(([key, value]) => [key.toLowerCase(), value])),
        ...this.translations.t("countrySelectFieldTranslations"),
      },
      initialCountry: this.defaultCountryCodeValue?.toLowerCase() || "",
      hiddenInput: () => ({ phone: this.phoneFieldTarget.name, country: "user[phone_country_code]" }),
      utilsScript: "/intl-tel-input/build/js/utils.js",
    });

    // TODO remove this part once we refactor profile & get rid of old js
    document.querySelector("input.iti__search-input")?.classList.add("no-floating-label");

    this.phoneFieldTarget.addEventListener("countrychange", this.handleCountryChange.bind(this));
    this.phoneFieldTarget.addEventListener("input", this.handleInputChange.bind(this));
    this.phoneFieldTarget.addEventListener("keyup", this.handleKeyUp.bind(this));
    this.phoneFieldTarget.addEventListener("paste", this.handlePaste.bind(this));
    this.phoneFieldTarget.form?.addEventListener("submit", this.handleSubmit.bind(this));
  }

  handleCountryChange() {
    const countryData = this.intlInstance.getSelectedCountryData();

    if (countryData.dialCode && this.phoneFieldTarget.value.includes(`+${countryData.dialCode}`)) {
      this.phoneFieldTarget.value = this.phoneFieldTarget.value.replace(`+${countryData.dialCode}`, "");
      return;
    }

    this.validatePhone();
  }

  handleInputChange() {
    const defaultValue = this.phoneFieldTarget.defaultValue || this.phoneFieldTarget.placeholder;

    if (this.phoneFieldTarget.value && this.phoneFieldTarget.value !== defaultValue) {
      if (this.intlInstance.isValidNumber()) {
        // user enterd a valid phone number --> could be autofilled or manually entered
        // set number to format for autofilled numbers
        this.intlInstance.setNumber(this.phoneFieldTarget.value);
      }
    }

    this.clearHTMLValidation();
    const submitButton = this.phoneFieldTarget.form?.querySelector("input[type=submit]") as HTMLInputElement;
    if (submitButton) {
      submitButton.disabled = false;
    }
  }

  handleKeyUp(event: KeyboardEvent) {
    if (event.key === "Backspace" && this.phoneFieldTarget.value === "") {
      this.intlInstance.setCountry("");
    }
  }

  handlePaste(event: ClipboardEvent) {
    event.preventDefault();
    this.intlInstance.setNumber(event.clipboardData?.getData("text") || "");
  }

  handleSubmit(event: Event) {
    if (this.phoneFieldTarget.value === "") {
      this.clearHTMLValidation();
      return;
    }

    event.preventDefault();
    this.validatePhone();

    if (this.intlInstance.isValidNumber()) {
      this.phoneFieldTarget.form?.submit();
    }
  }

  validatePhone() {
    if (this.intlInstance.isValidNumber()) {
      this.clearHTMLValidation();
      return;
    }

    const errorCode = this.intlInstance.getValidationError();
    const errorIdentifier = this.errorMappings[errorCode] || 0;
    const errorTranslation = this.translations.t(`validationErrors.${errorIdentifier}`);

    if (errorIdentifier) {
      if (errorIdentifier === "too_short" && this.phoneFieldTarget.value.length === 0 && !this.phoneFieldTarget.required) {
        this.phoneFieldTarget.setCustomValidity("");
      } else {
        this.phoneFieldTarget.setCustomValidity(errorTranslation);
      }

      this.phoneFieldTarget.reportValidity();
    }
  }

  clearHTMLValidation() {
    this.phoneFieldTarget.setCustomValidity("");
    this.phoneFieldTarget.reportValidity();
  }
}
